<template>
  <v-app class="v-application">
    <v-main>
      <navbar></navbar>
      <div class="main-container">
        <router-view />
      </div>
    </v-main>
    <Footer v-if="!hideFooter" />
  </v-app>
</template>
<script>
import Navbar from "@/components/navigators/Navbar.vue";
import Footer from "@/components/Footer.vue";
import "@/assets/css/core.scss";
export default {
  props: {
    hideFooter: Boolean
  },
  components: { Navbar, Footer },
};
</script>
<style lang="scss" scoped>
.v-application {
  background-position: center;
  background-image: url("./../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #000;
}
</style>
